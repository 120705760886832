<div class="card-container">
	<div class="card" *ngFor="let proposal of proposals; let i = index" [ngClass]="(proposal.state || '').toLowerCase()">
		<mat-card>
			<mat-card-title (click)="displayProposal(i)">{{ proposal.name }}</mat-card-title>
			<mat-card-content>
				<mat-chip-list>
					<mat-chip *ngFor="let chip of chipMap[proposal.state] || []" [color]="chip.color" selected="true" (click)="displayProposal(i)">
						{{chip.name}}
					</mat-chip>
				</mat-chip-list>
			</mat-card-content>
			<img mat-card-image [attr.src]="proposal.mainPictureUrl" *ngIf="proposal.mainPictureUrl" />
			<mat-card-actions>
				<button mat-button color="primary" *ngIf="!isExpired(proposal)" (click)="displayProposal(i)">Voir</button>
			</mat-card-actions>
		</mat-card>
	</div>
</div>