export interface IBaseEntity {
	id: number;
	updatedAt: Date;
	createdAt: Date;
}

export interface IAllAlertsUpdateProcess {
	id: number;
	startDate: Date;
	endDate: Date;
	allAlertNumber: number;
	errorNumber: number;
	doneAlertNumber: number;
}

export enum ResultStates {
	NEW = 'NEW',
	SELECTED_BY_TGC = 'SELECTED_BY_TGC',
	REJECTED_BY_TGC = 'REJECTED_BY_TGC',
}

export interface IResult {
	id: number;
	createdAt: Date;
	scrapperSearch: IScrapperSearch;
	url: string;
	imageUrl: string;
	name: string;
	price: number;
	deletedAt: Date;
	updatedAt: Date;
	state: ResultStates;
}

export interface IScrapperSearch extends IBaseEntity {
	filtersAsString: string;
	websiteResults: IWebsiteResult[];
	results: IResult[];
	execution: IScrapperExecution[];
	creatorId: string;
	creatorName: string;
	type: string;
	deletedAt: Date;
	client: IClient;
	allResultNumber: number;
	newResultNumber: number;
	lastExecutionResultsSummary: IResultSummary;
}

export interface IResultSummary {
	addedResults: IResult[];
	updatedResults: IResult[];
	removedResults: IResult[];
}

export interface IWebsiteResult {
	scrapperSearch: IScrapperSearch;
	website: string;
	status: string;
	results: IResult[];
	unwantedResults: number;
	totalResultNumber: number;
	url: string;
	duration: number;
	startDate: Date;
	endDate: Date;
}

export interface IScrapperExecution extends IBaseEntity {
	userName: string;
	userId: string;
	executionStart: Date;
	executionEnd: Date;
	executionTime: number;
	scrapperSearch: IScrapperSearch;
}

export interface IClient extends IBaseEntity {
	firstname: string;
	lastname: string;
	state: string;
	phone: string;
	email: string;
	postalCode: string;
	buyForm: IBuyForm;
	sellForm: ISellForm;
	alerts: IScrapperSearch[];
	history: IClientHistoryItem[];
	newResultNumber: number;
	legalEntity: ILegalEntity;
	owner: { id: number; email: string; name: string };
}

export interface IBuyForm extends IBaseEntity {
	useContactMe: boolean;
	aborted: boolean;
	hasQuit: boolean;
	otherEquipment: string;
	seatNumber: number;
	budget: string;
	kilometersPerYear: number;
	comment: string;
	brandOrModel: string;
	vehicules: string;
	oldCarKilometers: number;
	oldCarNumberPlate: string;
	ratingQuestions: INotationCriteriaWithRating[];
	importantCriteriaQuestions: IValuableCriteriaWithImportance[];
	equipments: IEquipment[];
	tripTypes: ITripType[];
	usageTypes: IUsageType[];
	client: IClient;
	editToken: string;
}

export interface INotationCriteriaWithRating extends IBaseEntity {
	criteria: INotationCriteria;
	form: IBuyForm;
	rating: number;
}

export interface INotationCriteria extends IBaseEntity {
	name: string;
	helpText: string;
	isEnabled: boolean;
	ratings: INotationCriteriaWithRating[];
}

export interface IValuableCriteriaWithImportance extends IBaseEntity {
	criteria: IValuableCriteria;
	form: IBuyForm;
	isImportant: boolean;
}

export interface IValuableCriteria extends IBaseEntity {
	name: string;
	isEnabled: boolean;
	valuableCriterias: IValuableCriteriaWithImportance[];
}

export interface ISellForm extends IBaseEntity {
	model: string;
	brand: string;
	kilometers: number;
	year: number;
	client: IClient;
}

export interface IClientHistoryItem extends IBaseEntity {
	name: string;
	user: string;
	type: string;
	payload: string;
	client: IClient;
}

export interface IEquipment extends IBaseEntity {
	name: string;
	isEnabled: boolean;
	forms: IBuyForm[];
}

export interface ITripType extends IBaseEntity {
	name: string;
	isEnabled: boolean;
	forms: IBuyForm[];
}

export interface IUsageType extends IBaseEntity {
	name: string;
	isEnabled: boolean;
	forms: IBuyForm[];
}

export interface IProposalPicture {
	id: number;
	name: string;
	isMain: boolean;
	url: string;
}

export interface IProposal {
	id: number;
	name: string;
	pictures: IProposalPicture[];
	price: number;
	year: string;
	gearbox: string;
	kilometers: number;
	energy: string;
	waranty: string;
	equipments: string;
	additionalInformation: string;
	client: IClient;
	state: ProposalStates;
	justification: string;
	createdAt: Date;
	mainPictureUrl: string;
}

export interface IDealership {
	name: string;
	brands: string[];
	description: string;
	contact: {
		name: string;
		phone: string;
		mail: string;
	};
	location: {
		name: string;
		number: number;
	};
}

export interface IRecommandation {
	id?: number;
	model: string;
	image: string;
	motor: string;
	mileage: number;
	year: number;
	price: number;
	warranty: string;
	equipments: string[];
}

export enum ProposalStates {
	NEW = 'NEW',
	WAITING = 'WAITING',
	EXPIRED = 'EXPIRED',
	SELECTED = 'SELECTED',
	REJECTED = 'REJECTED',
	DRAFT = 'DRAFT',
}

export interface ILegalEntity {
	id: number;
	name: string;
	slug: string;
	owner: { id: number; email: string; name: string };
}

export enum UserScope {
	AllLegalEntities = 'AllLegalEntities',
	LegalEntity = 'LegalEntity',
	User = 'User',
	ClientId = 'ClientId',
	Noop = 'Noop',
}
