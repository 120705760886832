import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit } from '@angular/core';
import {
	IProposal,
	ProposalStates,
} from '../../../../../core/interfaces';

import { ProposalService } from '../services/proposal.service';

export const PARAM_TOKEN = 'token';

interface IChip {
	name: string;
	color: string;
}

@Component({
	selector: 'app-proposals',
	templateUrl: './proposals.component.html',
	styleUrls: ['./proposals.component.scss'],
})
export class ProposalsComponent implements OnInit {
	public proposals: IProposal[];
	public chipMap: { [state: string]: IChip[] };

	public constructor(
		private proposalService: ProposalService,
		private router: Router,
		public route: ActivatedRoute,
		private element: ElementRef,
	) {
		this.proposals = [];

		this.chipMap = [
			ProposalStates.NEW,
			ProposalStates.EXPIRED,
			ProposalStates.REJECTED,
			ProposalStates.SELECTED,
			ProposalStates.WAITING,
		].reduce((acc, state) => {
			acc[state] = this.getChips(state);

			return acc;
		}, {});
	}

	public async ngOnInit(): Promise<void> {
		this.element.nativeElement.scrollIntoView();

		this.proposals = await this.proposalService.fetchProposals();
	}

	public displayProposal(proposalIndex: number): void {
		this.router.navigate([proposalIndex], { relativeTo: this.route });
	}

	public isExpired(proposal: IProposal): boolean {
		return proposal.state === ProposalStates.EXPIRED;
	}

	public getChips(state: ProposalStates): IChip[] {
		switch (state) {
			case ProposalStates.NEW:
				return [
					{
						name: 'Nouveau !',
						color: 'new',
					},
				];
			case ProposalStates.SELECTED:
				return [
					{
						name: 'Approuvé',
						color: 'selected',
					},
				];
			case ProposalStates.REJECTED:
				return [
					{
						name: 'Refusé',
						color: 'rejected',
					},
				];
			case ProposalStates.EXPIRED:
				return [
					{
						name: 'Expiré',
						color: 'grey',
					},
				];
			default:
				return [];
		}
	}
}
