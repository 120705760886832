import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	TrackByFunction,
	ViewChild,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { IProposal, IProposalPicture } from '../../../core/interfaces';
import Viewer from 'viewerjs/dist/viewer.esm.js';

@Component({
	selector: 'core-proposal',
	templateUrl: './proposal.component.html',
	styleUrls: ['./proposal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalComponent implements AfterViewInit, OnDestroy {
	@Input() public proposal: IProposal;
	public viewer: any;
	@ViewChild('images', { static: true })
	public imagesContainer: ElementRef;
	public trackById: TrackByFunction<IProposalPicture> = (_, p) => p.id;

	public constructor(public sanitizer: DomSanitizer) {}

	public ngAfterViewInit(): void {
		this.viewer = new Viewer(this.imagesContainer.nativeElement, {
			focus: true,
			fullscreen: true,
			movable: true,
			slideOnTouch: false,
			loading: false,
			toolbar: false,
			navbar: false,
			transition: false,
		})
	}

	public ngOnDestroy(): void {
		if (this.viewer) {
			this.viewer.destroy();
		}
	}
}
