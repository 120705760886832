<mat-card>
	<a [routerLink]="['../']">Mes propositions</a> /
	<span>{{ proposal.name }}</span>
</mat-card>

<div class="container">
	<core-proposal [proposal]="proposal">
		<div class="response-container" *ngIf="canApproveOrReject()">
			<h2>Alors ? Qu'en pensez-vous ?</h2>
			<button mat-raised-button class="green" (click)="approveThisProposal(true)">Je suis intéressé(e)</button>
			<button mat-raised-button class="red" (click)="approveThisProposal(false)">Je n'en veux pas</button>
		</div>
		<div class="response green" *ngIf="isApproval()">
			Vous avez accepté cette proposition.
		</div>
		<div class="response red" *ngIf="isRejection()">
			Vous avez refusé cette proposition.
		</div>
	</core-proposal>
</div>