<h2 mat-dialog-title>Confirmation</h2>

<mat-dialog-content *ngIf="isLoading$ | async" class="loading">
	<mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content *ngIf="!(isLoading$ | async)">
	<div>{{ explanationText }}</div>
	<mat-form-field>
		<textarea [(ngModel)]="justification" matInput matTextareaAutosize [matAutosizeMinRows]="8" [placeholder]="isApproval ? 'Un commentaire ?' : 'Explications...'"></textarea>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!(isLoading$ | async)">
	<button mat-raised-button (click)="confirm()" [ngClass]="isApproval ? 'green' : 'red'">Oui</button>
	<button mat-button (click)="close()">Annuler</button>
</mat-dialog-actions>
