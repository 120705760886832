import {
	PARAM_PROPOSAL_INDEX,
	ProposalComponent,
} from './proposal/proposal.component';
import {
	PARAM_TOKEN,
	ProposalsComponent,
} from './proposals/proposals.component';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateProposal } from './guards/canActivateProposal';
import { CanActivateProposals } from './guards/canActivateProposals';
import { UnknownComponent } from './unknown/unknwon.component';

const clientRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'proposals',
	},
	{
		path: 'unknown',
		component: UnknownComponent,
	},
	{
		path: 'proposals',
		canActivate: [CanActivateProposals],
		children: [
			{ path: '', pathMatch: 'full', component: ProposalsComponent },
			{
				path: `:${PARAM_PROPOSAL_INDEX}`,
				component: ProposalComponent,
				canActivate: [CanActivateProposal],
			},
		],
	},
	{
		path: `:${PARAM_TOKEN}`,
		component: ProposalsComponent,
		canActivate: [CanActivateProposals],
	},
];

export const clientRouting = RouterModule.forRoot(clientRoutes, { relativeLinkResolution: 'legacy' });
