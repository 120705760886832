import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProposalComponent } from './proposal.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,
		MatInputModule,
		ReactiveFormsModule,
	],
	declarations: [ProposalComponent],
	providers: [],
	exports: [ProposalComponent],
})
export class ProposalModule {}
