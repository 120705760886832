import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '../models/user';

@Injectable()
export class UserService {
	public userToken: string;
	public user: IUser;

	public get authorizedHeader(): {
		headers: HttpHeaders | { [header: string]: string };
	} {
		return this.getAuthorizedHeader(this.userToken);
	}

	public getAuthorizedHeader(
		token: string,
	): {
		headers: HttpHeaders | { [header: string]: string };
	} {
		return {
			headers: { Authorization: `User ${token}` },
		};
	}

	public constructor(private httpClient: HttpClient) {}

	public async setToken(token: string): Promise<void> {
		this.user = await this.fetchUser(token);

		this.userToken = token;
		this.storeToken(token);
	}

	public async tryRetrieveUser(): Promise<boolean> {
		const token = this.retreiveStoredToken();

		if (token) {
			await this.setToken(token);

			return true;
		}

		return false;
	}

	private async fetchUser(token): Promise<IUser> {
		return await this.httpClient
			.get<IUser>('/api/users/me', this.getAuthorizedHeader(token))
			.toPromise();
	}

	private storeToken(token: string): void {
		try {
			localStorage.setItem('USER_TOKEN', token);
		} catch (error) {
			//
		}
	}

	private retreiveStoredToken(): string {
		try {
			return localStorage.getItem('USER_TOKEN');
		} catch (error) {
			return null;
		}
	}
}
