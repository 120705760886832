import {
	IProposal,
	ProposalStates,
} from '../../../../../core/interfaces';

import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { sortBy } from 'lodash';

@Injectable()
export class ProposalService {
	private _proposals$: BehaviorSubject<IProposal[]>;
	private sortWeight: { [state: string]: number };

	public constructor(
		private httpClient: HttpClient,
		private userService: UserService,
	) {
		this._proposals$ = new BehaviorSubject(null);

		this.sortWeight = [
			ProposalStates.NEW,
			ProposalStates.WAITING,
			ProposalStates.SELECTED,
			ProposalStates.REJECTED,
			ProposalStates.EXPIRED,
		].reduce((acc, state, idx) => {
			acc[state] = idx;

			return acc;
		}, {});
	}

	public getProposalAtIndex(_index: string): IProposal {
		const index = parseInt(_index, 10);

		return this._proposals$.value[index];
	}

	public async refetchProposals(): Promise<IProposal[]> {
		this._proposals$.next(null);

		return await this.fetchProposals();
	}

	public async fetchProposals(): Promise<IProposal[]> {
		if (this._proposals$.value === null) {
			const proposals = await this.httpClient
				.get<IProposal[]>(
					'/api/proposals',
					this.userService.authorizedHeader,
				)
				.toPromise();

			const sorted = sortBy(
				sortBy(proposals, p => p.createdAt),
				p => this.sortWeight[p.state],
			);

			this._proposals$.next(sorted);
		}

		return this._proposals$.value;
	}

	public async updateState(
		proposal: IProposal,
		state: ProposalStates,
		justification?: string,
	): Promise<IProposal> {
		const newProposal = await this.httpClient
			.put<IProposal>(
				`/api/proposals/${proposal.id}/updateState`,
				{ state, justification },
				this.userService.authorizedHeader,
			)
			.toPromise();

		const newProposals = this._proposals$.value.map(
			oldVal => (oldVal === proposal ? { ...newProposal } : oldVal),
		);

		this._proposals$.next(newProposals);
		proposal.state = newProposal.state;

		return newProposal;
	}
}
