import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { PARAM_PROPOSAL_INDEX } from '../proposal/proposal.component';
import { ProposalService } from '../services/proposal.service';

@Injectable()
export class CanActivateProposal implements CanActivate {
	public constructor(
		private proposalService: ProposalService,
		private router: Router,
	) {}

	public async canActivate(
		route: ActivatedRouteSnapshot,
	): Promise<boolean> {
		const proposalIndex = parseInt(
			route.paramMap.get(PARAM_PROPOSAL_INDEX),
			10,
		);
		const proposals = await this.proposalService.fetchProposals();

		const isValid =
			proposalIndex !== null &&
			proposalIndex >= 0 &&
			proposalIndex < proposals.length;

		if (!isValid) {
			this.router.navigate([]);
		}

		return isValid;
	}
}
