import { Component, Inject } from '@angular/core';
import {
	IProposal,
	ProposalStates,
} from '../../../../../core/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';
import { ProposalService } from '../services/proposal.service';
import { Router } from '@angular/router';

export interface IProposalFormData {
	isApproval: boolean;
	proposal: IProposal;
}

@Component({
	selector: 'app-proposal-form',
	templateUrl: './proposal-form.component.html',
	styleUrls: ['./proposal-form.component.scss'],
})
export class ProposalFormComponent {
	public isApproval: boolean;
	public proposal: IProposal;
	public justification: string;
	public explanationText: string;

	public isLoading$: BehaviorSubject<boolean>;

	public constructor(
		private proposalService: ProposalService,
		private dialogRef: MatDialogRef<ProposalFormComponent>,
		@Inject(MAT_DIALOG_DATA)
		{ isApproval, proposal }: IProposalFormData,
		private router: Router,
	) {
		this.justification = '';
		this.isApproval = isApproval;
		this.proposal = proposal;

		this.isLoading$ = new BehaviorSubject(false);

		this.explanationText = isApproval
			? 'Êtes-vous intéressé(e) par ce véhicule ?'
			: 'Vous vous apprêtez à refuser cette offre. Pour que nous puissions cibler au mieux votre besoin, nous aimerions savoir pourquoi cette proposition ne vous convient pas.';
	}

	public close(): void {
		this.dialogRef.close();
	}

	public async confirm(): Promise<void> {
		const justification = (this.justification || '').trim();
		const state = this.isApproval
			? ProposalStates.SELECTED
			: ProposalStates.REJECTED;

		this.isLoading$.next(true);

		try {
			await this.proposalService.updateState(
				this.proposal,
				state,
				justification,
			);

			await this.proposalService.refetchProposals();
		} catch (e) {
			//
		} finally {
			this.isLoading$.next(false);
			this.router.navigate(['proposals']);

			this.dialogRef.close();
		}
	}
}
