import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
} from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CanActivateProposal } from './guards/canActivateProposal';
import { CanActivateProposals } from './guards/canActivateProposals';
import { FormsModule } from '@angular/forms';
import { ProposalComponent } from './proposal/proposal.component';
import { ProposalFormComponent } from './proposal-form/proposal-form.component';
import { ProposalModule } from '../../../core/proposal/proposal.module';
import { ProposalService } from './services/proposal.service';
import { ProposalsComponent } from './proposals/proposals.component';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { UnknownComponent } from './unknown/unknwon.component';
import { UserService } from './services/user.service';
import { clientRouting } from './app.routes';
import { environment } from '../environments/environment';
import locale_extended_fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { createErrorHandler } from '@sentry/angular';

registerLocaleData(locale_extended_fr);

@NgModule({
	declarations: [
		AppComponent,
		ProposalComponent,
		ProposalFormComponent,
		ProposalsComponent,
		UnknownComponent,
	],
	imports: [
		clientRouting,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		MatButtonModule,
		MatCardModule,
		MatChipsModule,
		MatDialogModule,
		MatInputModule,
		MatProgressSpinnerModule,
		ProposalModule,
	],
	providers: [
		UserService,
		ProposalService,
		CanActivateProposal,
		CanActivateProposals,
		{ provide: LOCALE_ID, useValue: 'fr-fr' },
		{ provide: ErrorHandler, useValue: createErrorHandler({ logErrors: environment.production }) },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
