import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { PARAM_TOKEN } from '../proposals/proposals.component';
import { UserService } from '../services/user.service';

@Injectable()
export class CanActivateProposals implements CanActivate {
	public constructor(
		private userService: UserService,
		private router: Router,
	) {}

	public async canActivate(
		route: ActivatedRouteSnapshot,
	): Promise<boolean> {
		const canRetrieveUser = await this.canRetrieveUser(route);

		if (!canRetrieveUser) {
			this.router.navigate(['unknown']);
		}

		return canRetrieveUser;
	}

	private async canRetrieveUser(
		route: ActivatedRouteSnapshot,
	): Promise<boolean> {
		const token = route.paramMap.get(PARAM_TOKEN);

		// If there is a token in URL, use it
		if (token) {
			try {
				await this.userService.setToken(token);
				this.router.navigate([]);
			} catch (e) {
				return false;
			}

			return true;
		}

		// If the app already loaded, take in memory token
		if (this.userService.userToken) {
			return true;
		}

		// Try get from localStorage
		try {
			const hasRetrievedUser = await this.userService.tryRetrieveUser();

			return hasRetrievedUser;
		} catch (e) {
			return false;
		}
	}
}
