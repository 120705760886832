<h1>{{ proposal.name }}</h1>

<div class="left column" #images>
	<div
		class="img-container"
		*ngFor="let picture of proposal.pictures; let i = index; trackBy: trackById"
	>
		<img [attr.src]="sanitizer.bypassSecurityTrustUrl(picture.url)" [attr.alt]="proposal.name + ' - Image ' + i">
	</div>
</div>
<div class="right column">
	<div>
		<h3>Informations</h3>
		<table>
			<tbody>
				<tr>
					<td>Prix</td>
					<td>{{ proposal.price | currency:'EUR':'symbol':'1.0-2' }}</td>
				</tr>
				<tr *ngIf="proposal.year">
					<td>Année</td>
					<td>{{ proposal.year }}</td>
				</tr>
				<tr *ngIf="proposal.kilometers">
					<td>Kilométrage</td>
					<td>{{ proposal.kilometers | number }} km</td>
				</tr>
				<tr *ngIf="proposal.energy">
					<td>Énergie</td>
					<td>{{ proposal.energy }}</td>
				</tr>
				<tr *ngIf="proposal.gearbox">
					<td>Boîte de vitesses</td>
					<td>{{ proposal.gearbox }}</td>
				</tr>
				<tr *ngIf="proposal.waranty">
					<td>Garantie</td>
					<td>{{ proposal.waranty }}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="equipments">
		<h3>Équipements</h3>
		<div>{{ proposal.equipments }}</div>
	</div>

	<div class="info" *ngIf="proposal.additionalInformation && proposal.additionalInformation.trim()">
		<h3>Plus d'informations</h3>
		<div>{{ proposal.additionalInformation }}</div>
	</div>

	<ng-content></ng-content>
</div>
