import { Component, ElementRef, OnInit } from '@angular/core';
import {
	IProposal,
	ProposalStates,
} from '../../../../../core/interfaces';
import {
	IProposalFormData,
	ProposalFormComponent,
} from '../proposal-form/proposal-form.component';
import { map, take } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProposalService } from '../services/proposal.service';

export const PARAM_PROPOSAL_INDEX = 'proposalId';
@Component({
	selector: 'app-proposal',
	templateUrl: './proposal.component.html',
	styleUrls: ['./proposal.component.scss'],
})
export class ProposalComponent implements OnInit {
	public proposal: IProposal = null;

	public constructor(
		private activatedRoute: ActivatedRoute,
		private proposalService: ProposalService,
		private dialog: MatDialog,
		private element: ElementRef,
	) {}

	public ngOnInit(): void {
		this.element.nativeElement.scrollIntoView();

		this.activatedRoute.paramMap
			.pipe(
				map(params => params.get(PARAM_PROPOSAL_INDEX)),
				map(index => this.proposalService.getProposalAtIndex(index)),
				take(1),
			)
			.subscribe(async proposal => {
				this.proposal = proposal;

				if (proposal.state === ProposalStates.NEW) {
					await this.proposalService.updateState(
						proposal,
						ProposalStates.WAITING,
					);
				}
			});
	}

	public approveThisProposal(isApproval: boolean): void {
		this.dialog.open(ProposalFormComponent, {
			data: {
				isApproval,
				proposal: this.proposal,
			} as IProposalFormData,
		});
	}

	public canApproveOrReject(): boolean {
		return (
			this.proposal.state === ProposalStates.NEW ||
			this.proposal.state === ProposalStates.WAITING
		);
	}

	public isApproval(): boolean {
		return this.proposal.state === ProposalStates.SELECTED;
	}

	public isRejection(): boolean {
		return this.proposal.state === ProposalStates.REJECTED;
	}
}
